<template>
  <div class="mt-3  px-2">
    <v-row dense>
      <v-col
          v-for="i in 4"
          :key="i"
          cols="6">
        <Uploader
            @change="(e)=>{
              addImage(e,i)
            }"
            width="100%"/>
      </v-col>

      <v-col :cols="12">
        <v-textarea
            outlined
            v-model="job.description"
            label="توضیحات"
            height="80"
        />
      </v-col>
      <v-col
          class="d-flex align-center justify-start"
          :cols="12">
        <v-btn
            block
            large
            @click="submit"
            color="success">
          ثبت آگهی
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Uploader from "@/components/Uploader"

export default {

  name: "Step2",

  components: {
    Uploader
  },

  data() {
    return {
      job: {
        description: '',
        image1: null,
        image2: null,
        image3: null,
        image4: null,
      }
    }
  },
  computed: {},

  methods: {
    submit() {
      this.$emit('nextStep', this.job)
    },
    addImage(file, index) {
      this.job[`image${index}`] = file.url;
    },
  },

}
</script>

<style scoped>

</style>